import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withRouter } from 'next/router';
import LocationSearch from '@components/Forms/LocationSearch';
import { CompanyLogoIcon, CompanySymbolIcon } from '@components/Icons';
import UserMenu from '@components/Navigation/UserMenu';
import Actions from '@redux/actions';
import SearchFilterUtility from '@utilities/SearchFilterUtility';

class Nav extends Component {
  state = {
    isClient: false,
  };

  componentDidMount() {
    // This is used for the listing search link key
    this.setState({
      isClient: true,
    });
  }

  buildHousemateSearchQueryObject = () => {
    const { filters } = this.props;
    const start = filters.get('start');
    const end = filters.get('end');

    if (start && end) {
      return {
        start,
        end,
      };
    }

    return {};
  };

  render() {
    const { isClient } = this.state;
    const { filters, isFullWidth, navClassName, router, showNavActions, showSearch, user } = this.props;

    const listingSearchRedirect = SearchFilterUtility.getListingSearchRedirect(filters);

    return (
      <header className={`a--top z--100 bg--white ${navClassName}`}>
        <nav className={`${isFullWidth ? '' : 'mw--md'} h--100p m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs layout-row layout-align-start-center`}>
          <div className="layout-row layout-align-start-center">
            <Link href="/">
              <a aria-label="Kopa home" className="hide-gt-xs hover-none">
                <CompanySymbolIcon className="icon--32 hover-none icon--pacific w--auto" />
              </a>
            </Link>
          </div>
          <div className="layout-row layout-align-start-center">
            <Link href="/">
              <a aria-label="Kopa home" className="hide-xs hover-none">
                <CompanyLogoIcon className="block h--48 w--auto" />
              </a>
            </Link>
            { showSearch && (
              <div className="hide-xs hide-sm m-left--x-large miw--368-gt-md miw--288">
                <LocationSearch identifier="nav-location-search" />
              </div>
            )}
          </div>

          <span className="flex" />

          { showNavActions && (
            <div className="h--100p layout-row layout-align-start-center">
              <div className="hide-xs hide-sm h--100p layout-column layout-align-center-center m-horizontal--small" id="nav-browse-rentals-button">
                <Link href={listingSearchRedirect} key={`${isClient ? 'client' : 'server'}-listing-search-link`}>
                  <a className={`h--100p black hover-none layout-row layout-align-center-center ${router && (router.pathname.includes('/s/') || router.pathname.includes('/c/')) ? 'selected-tab' : ''}`}>
                    Browse Rentals
                  </a>
                </Link>
              </div>
              { (user.isEmpty() || user.get('is_renter')) && (
                <div className="hide-xs hide-sm h--100p layout-column layout-align-center-center m-horizontal--small" id="nav-find-housemates-button">
                  <Link href={{ pathname: `/h/${filters.get('stateSlug')}/${filters.get('citySlug')}`, query: this.buildHousemateSearchQueryObject() }}>
                    <a className={`h--100p black hover-none layout-row layout-align-center-center ${router && router.pathname.includes('/h/') ? 'selected-tab' : ''}`}>
                      Find Housemates
                    </a>
                  </Link>
                </div>
              )}
              { user.isEmpty() && (
                <div className="hide-xs hide-sm h--100p layout-column layout-align-center-center m-horizontal--small" id="nav-landlords-button">
                  <Link href="/host">
                    <a className={`h--100p black hover-none layout-row layout-align-center-center ${router && router.pathname === '/host' ? 'selected-tab' : ''}`}>
                      For Hosts
                    </a>
                  </Link>
                </div>
              )}
              <UserMenu />
            </div>
          )}
        </nav>
      </header>
    );
  }
}

Nav.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  // Optional
  isFullWidth: PropTypes.bool,
  navClassName: PropTypes.string,
  showNavActions: PropTypes.bool,
  showSearch: PropTypes.bool,
};

Nav.defaultProps = {
  isFullWidth: false,
  navClassName: '',
  showNavActions: true,
  showSearch: false,
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
  user: state.get('User').get('object'),
});

export default withRouter(
  connect(mapStateToProps, {
    updateFilters: Actions.updateFilters,
  })(Nav)
);
