import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import UserIdConstants from '@domain/Users/UserIdConstants';
import ImageService from '@services/ImageService';

const Headshot = ({ blur, id, imgUrl, isLinked, name, size, title }) => {
  const imgJsx = (
    <div className={`square--${size} b--round b--gray-2 b-around--xs overflow-hidden layout-row`}>
      <Image
        alt={imgUrl ? `${name} headshot` : `${name} placeholder headshot`}
        className={`bg--white ${blur ? 'blur' : ''}`}
        height={size}
        quality="100"
        src={imgUrl ? imgUrl : ImageService.headshotDefaultUser}
        title={title}
        width={size}
      />
    </div>
  );

  if (isLinked && id && id !== UserIdConstants.DELETED_RENTER_ID) {
    return (
      <Link href={`/users/${id}`}>
        <a aria-label={`Go to ${name}'s Kopa profile`} className="inline-flex hover-none">
          {imgJsx}
        </a>
      </Link>
    );
  }

  return imgJsx;
};

Headshot.propTypes = {
  // Required
  size: PropTypes.number.isRequired,
  // Optional
  blur: PropTypes.bool,
  id: PropTypes.string,
  imgUrl: PropTypes.string,
  isLinked: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
};

Headshot.defaultProps = {
  blur: false,
  id: null,
  imgUrl: null,
  isLinked: true,
  name: 'User',
  title: null,
};

export default Headshot;
