import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GeneralStatic from '@utilities/static/GeneralStatic';

class NavDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  componentDidMount() {
    // Close the dropdown if click outside of it
    window.addEventListener('click', this.handleNavOnClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleNavOnClick);
  }

  handleNavOnClick = (event) => {
    if (document.getElementById('nav-dropdown-target') && !document.getElementById('nav-dropdown-target').contains(event.target)) {
      const dropdown = document.getElementById('nav-dropdown');
      if (dropdown && dropdown.classList.contains('show')) {
        this.toggleDropdown();
      }
    }
  }

  toggleDropdown () {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  render () {
    const { className, closeButton, isOpen, menuItems, textColor } = this.props;

    return (
      <TransitionGroup component={null}>
        { isOpen && (
          <CSSTransition classNames="item--fade-fast" timeout={GeneralStatic.transitionDefault}>
            <div className={`z--1500 fixed a--top a--left w--100p h--100p overflow-auto ${className}`}>
              { closeButton }
              { menuItems
                .filter(item => item.show)
                .map((item, i) => (
                  <div className={`p-top--large p-left--medium ${item.className || ''}`} key={`${item.href}-${i}`}>
                    <Link href={item.href}>
                      <a className={`${textColor} hover-none layout-row layout-align-start-center ${item.linkClassName || ''}`}>
                        <h4 className={item.showDot ? 'unread-dot' : ''}>{ item.label }</h4>
                      </a>
                    </Link>
                  </div>
                ))}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}

NavDrawer.propTypes = {
  // Required
  closeButton: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
  // Optional
  className: PropTypes.string,
  textColor: PropTypes.string,
};

NavDrawer.defaultProps = {
  className: '',
  textColor: 'black',
};

export default NavDrawer;
