import React from 'react';
import PropTypes from 'prop-types';

const KopaForGoodIcon = (props) => (
  <svg aria-hidden="true" className={props.className} fill="currentColor" fillRule="evenodd" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.627.77c.465.538.69 1.209.69 2.051 0 .842-.225 1.513-.69 2.051-.442.51-1.06.758-1.894.758-.835 0-1.459-.249-1.908-.761-.47-.537-.698-1.207-.698-2.048 0-.84.228-1.511.698-2.048C7.275.259 7.901.006 8.728 0c.835.006 1.457.258 1.899.77M10.627 12.964c.465.538.69 1.209.69 2.051 0 .843-.225 1.513-.69 2.05-.442.512-1.06.76-1.894.76-.835 0-1.459-.25-1.908-.762-.47-.537-.698-1.207-.698-2.048 0-.84.228-1.511.698-2.048.45-.514 1.076-.767 1.903-.773.835.006 1.457.258 1.899.77M16.753 6.867c.465.538.69 1.209.69 2.051 0 .843-.225 1.513-.69 2.051-.441.511-1.06.759-1.894.759-.835 0-1.458-.25-1.907-.762-.47-.537-.699-1.207-.699-2.048 0-.84.228-1.511.7-2.048.448-.514 1.074-.767 1.902-.773.835.006 1.456.258 1.898.77M4.5 6.867c.465.538.69 1.209.69 2.051 0 .843-.225 1.513-.69 2.051-.441.511-1.06.759-1.894.759-.835 0-1.458-.25-1.907-.762C.23 10.429 0 9.759 0 8.918c0-.84.228-1.511.7-2.048.448-.514 1.074-.767 1.902-.773.835.006 1.456.258 1.898.77" transform="translate(3 3)" />
  </svg>
);

KopaForGoodIcon.propTypes = {
  // Required
  className: PropTypes.string.isRequired,
};

export default KopaForGoodIcon;
