import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Button from '@components/Core/Button';
import DropdownMenu from '@components/Core/DropdownMenu';
import Loader from '@components/Core/Loader';
import { CloseIcon, MenuIcon } from '@components/Icons';
import Headshot from '@components/Images/Headshot';
import AuthControls from '@components/Navigation/AuthControls';
import HostMenu from '@components/Navigation/HostMenu';
import NavDrawer from '@components/Navigation/NavDrawer';
import ProfileUtility from '@utilities/ProfileUtility';
import SearchFilterUtility from '@utilities/SearchFilterUtility';

const UserMenu = ({ filters, isAuthLoading, isDirectBookingNav, pendingRequestCount, screenSizes, unreadMessageCount, user }) => {
  const [showRightNavDrawer, setShowRightNavDrawer] = useState(false);
  const isDirectBookingUser = ProfileUtility.isDirectBookingUser(user);
  const listingSearchHref = SearchFilterUtility.getListingSearchRedirect(filters);
  const housemateSearchHref = `/h/${filters.get('stateSlug')}/${filters.get('citySlug')}`;

  if (isAuthLoading) {
    return (
      <Loader className="loader--small" />
    );
  }

  const USER_MENU_ITEMS = [{
    href: listingSearchHref,
    label: 'Browse Rentals',
    show: !screenSizes.get('gtSm'),
  }, {
    href: housemateSearchHref,
    label: 'Find Housemates',
    show: !screenSizes.get('gtSm'),
  }, {
    href: '/host',
    label: 'For Hosts',
    show: !screenSizes.get('gtSm'),
  }, {
    href: '/login',
    label: 'Log In',
    show: user.isEmpty(),
  }, {
    className: 'w--100p p-right--medium',
    href: '/signup',
    label: 'Sign up',
    linkClassName: 'button primary',
    show: user.isEmpty(),
  }, {
    className: !screenSizes.get('gtSm') ? 'b-top--xs b--gray-2 m-top--large' : '',
    href: '/account/dashboard',
    label: 'Dashboard',
    show: user.get('is_host'),
  }, {
    className: !screenSizes.get('gtSm') && user.get('is_renter') ? 'b-top--xs b--gray-2 m-top--large' : '',
    href: '/account/inbox',
    label: 'Inbox',
    show: !user.isEmpty(),
    showDot: unreadMessageCount > 0,
  }, {
    href: '/account/requests',
    label: 'Requests & Stays',
    show: !user.isEmpty(),
    showDot: pendingRequestCount > 0,
  }, {
    href: '/account/reviews',
    label: 'Reviews',
    show: !user.isEmpty(),
  }, {
    href: '/account/listings',
    label: 'Listings',
    show: user.get('is_host'),
  }, {
    href: '/account/payments',
    label: 'Payment History',
    show: user.get('is_renter'),
  }, {
    href: '/account/transactions',
    label: 'Transaction History',
    show: user.get('is_host'),
  }, {
    className: 'b-top--xs b--gray-2 m-top--large',
    href: `/users/${user.get('id')}`,
    label: 'My Profile',
    show: !user.isEmpty(),
    showDot: !user.get('about'),
  }, {
    href: '/account/housing-search',
    label: 'Favorites',
    show: user.get('is_renter'),
  }, {
    href: '/account/saved-searches',
    label: 'Saved Searches',
    show: user.get('is_renter'),
  }, {
    href: `/book/${user.getIn(['company', 'slug'])}`,
    label: user.getIn(['company', 'display_name']),
    show: isDirectBookingUser && user.getIn(['company', 'display_name']),
  }, {
    href: '/settings',
    label: 'Account Settings',
    show: !user.isEmpty(),
  }, {
    href: '/refer-renter',
    label: 'Referrals',
    show: user.get('is_renter'),
  }, {
    href: '/refer-host',
    label: 'Referrals',
    show: user.get('is_host'),
  }, {
    className: 'b-top--xs b--gray-2 m-top--large',
    href: '/contact',
    label: 'Help',
    show: !user.isEmpty(),
  }, {
    className: 'p-bottom--large',
    href: '/logout',
    label: 'Sign Out',
    show: !user.isEmpty(),
  }];

  return (
    <div className="m-left--small layout-row layout-align-start-center">
      <HostMenu />
      <Button ariaLabel="Mobile nav drawer menu" className={`button-icon ${user.isEmpty() ? 'hide-gt-xs' : 'hide-gt-sm b-around--xs b--gray-2 b--rounded p-around--x-small'}`} onClick={setShowRightNavDrawer.bind(this, true)} targetClassName="bg--transparent">
        { !user.isEmpty() && (
          <Headshot id={user.get('id')} imgUrl={user.get('profile_image') ? user.getIn(['profile_image', 'url_160']) : null} isLinked={false} name={user.get('first_name')} size={30} />
        )}
        { showRightNavDrawer ? (
          <CloseIcon className="icon--16 icon--black icon-stroke--sm m-left--x-small" />
        ) : (
          <MenuIcon className="icon--16 icon--black m-left--x-small" />
        )}
      </Button>

      { user.isEmpty() ? (
        <AuthControls isDirectBookingNav={isDirectBookingNav} />
      ) : (
        <DropdownMenu
          className="miw--48 hide-sm hide-xs"
          contentClassName="pull-right"
          dropdownTarget={(isDropdownOpen) => (
            <>
              <Headshot id={user.get('id')} imgUrl={user.get('profile_image') ? user.getIn(['profile_image', 'url_50']) : null} isLinked={false} name={user.get('first_name')} size={30} />
              { isDropdownOpen ? (
                <CloseIcon className="icon--16 icon--black icon-stroke--sm m-left--x-small" />
              ) : (
                <MenuIcon className="icon--16 icon--black m-left--x-small" />
              )}
            </>
          )}
          focusOutline={false}
          identifier="nav-dropdown-menu"
          menuContents={() => (
            USER_MENU_ITEMS
              .filter(item => item.show)
              .map(item => (
                <div className={`p-top--large p-left--medium ${item.className}`} key={item.href}>
                  <Link href={item.href}>
                    <a className="black hover-none layout-row layout-align-start-center">
                      <h4 className={item.showDot ? 'unread-dot' : ''}>{ item.label }</h4>
                    </a>
                  </Link>
                </div>
              )))}
          targetClassName="button-icon b-around--xs b--gray-2 p-around--x-small"
        />
      )}

      <NavDrawer
        className="bg--gray-3"
        closeButton={
          <div className="m-top--large p-horizontal--medium layout-row layout-align-end-center">
            <Button className="button-icon" onClick={setShowRightNavDrawer.bind(this, false)}>
              <CloseIcon className="icon--16 icon--black icon-stroke--sm" />
            </Button>
          </div>
        }
        isOpen={showRightNavDrawer}
        menuItems={USER_MENU_ITEMS}
        textColor="black"
      />
    </div>
  );
};

UserMenu.propTypes = {
  // Required
  filters: PropTypes.object.isRequired,
  isAuthLoading: PropTypes.bool.isRequired,
  screenSizes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  // Optional
  isDirectBookingNav: PropTypes.bool,
  pendingRequestCount: PropTypes.number,
  unreadMessageCount: PropTypes.number,
};

UserMenu.defaultProps = {
  isDirectBookingNav: false,
  pendingRequestCount: 0,
  unreadMessageCount: 0,
};

const mapStateToProps = (state) => ({
  filters: state.get('Search').get('filters'),
  isAuthLoading: state.get('User').get('isTokenLoading'),
  pendingRequestCount: state.get('User').get('pendingRequestCount'),
  screenSizes: state.get('ScreenSizes'),
  unreadMessageCount: state.get('User').get('unreadMessageCount'),
  user: state.get('User').get('object'),
});

export default connect(mapStateToProps, {})(UserMenu);
